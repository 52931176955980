<template>
  <main class="page-wrapper">
    <div class="header">
      <p class="-stb">Management Area</p>
      <p class="-xstr">Administer your organization here.</p>
    </div>
    <hr class="hr-form" />
    <OrgManagementListing />
  </main>
</template>

<script>
import OrgManagementListing from "../../components/forms/organizations/OrgManagementListing.vue";
export default {
  components: {
    OrgManagementListing,
  },
};
</script>

<style scoped>
.page-wrapper {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  gap: 1rem;
}
.header {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}
</style>