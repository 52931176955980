<template>
  <main class="width100 flex justify-end gap16 btns-wrapper">
    <div class="btn" @click="$emit('closeModal')">
      <p class="-xstb">Cancel</p>
    </div>
    <div class="btn" @click="$emit('handleClick')">
      <p class="-xstb">Save</p>
    </div>
  </main>
</template>

<script>
export default {
  emits: ["closeModal", "handleClick"],
};
</script>

<style scoped>
.btn {
  cursor: pointer;
  border-radius: 4px;
  padding-block: 8px;
  padding-inline: 16px;
  user-select: none;
}
.btn:first-child {
  border: 1px solid var(--gray5);
}
.btn:last-child {
  background: var(--secondary);
  color: var(--white1);
  transition: box-shadow 500ms ease;
}
.btn:first-child:hover {
  background: var(--gray6);
}
.btn:last-child:hover {
  box-shadow: inset 0 -40px 0 0 var(--success);
}
.btn:active {
  scale: 0.9;
}
</style>